import { Text } from '@latitude/text';
import cn from 'classnames';
import { ElementRef, forwardRef } from 'react';
import { signalVariants } from 'variants';

import type { SignalProps, SignalStatus } from 'types';

const Signal = forwardRef<ElementRef<'div'>, SignalProps>(
  (
    { status = 'default', border = false, label = '', className, ...rest },
    ref,
  ) => {
    return (
      <div
        {...rest}
        className={cn([
          className,
          'flex',
          'items-center',
          'gap-sizing-sys-size-8',
        ])}
        ref={ref}
        data-testid="signal-container"
      >
        <div
          data-testid="signal-element"
          className={cn(signalVariants({ status, border }))}
        />
        {label && <Text color="strong">{label}</Text>}
      </div>
    );
  },
);

Signal.displayName = 'Signal';

export { Signal, signalVariants, type SignalProps, type SignalStatus };
